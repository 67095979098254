import { Restaurant, RestaurantService } from 'gc-common';
import React, { useEffect, useState } from 'react';
import { Animated, RefreshControl, View } from 'react-native';
import { useAnimatedTopHeader } from '../../components/AnimatedTopHeader';
import { PicCarousel } from '../../components/PicCarousel/PicCarousel';
import { Colors } from '../../constants/Colors';
import { RootStackScreenProps } from '../../types';
import { DEFAULT_RESTAURANT_IMAGE } from '../../types/Constants';
import { EventLogger } from '../../utils/EventLogger';
import { usePopDish } from './usePopDish';
import { usePosts } from './usePosts';
import { useRestaurantDetails } from './useRestaurantDetails';
import { useReviews } from './useReviews';

export const RestaurantDetailScreen = ({
    route,
    navigation,
}: RootStackScreenProps<'RestaurantDetail'>) => {
    const restaurantId = route.params.id;
    const [restaurant, setRestaurant] = useState<Restaurant>();
    const restaurantDetail = useRestaurantDetails(restaurant);
    const animationHeader = useAnimatedTopHeader(navigation, Colors.white);
    const reviewHook = useReviews(restaurant);
    const popDish = usePopDish(restaurant);
    const posts = usePosts(restaurant);
    const pics = restaurant?.image_urls || [];

    useEffect(() => {
        console.log('Restuarant detail...', restaurantId);
        EventLogger.viewRestaurant(restaurantId);
        fetchData(restaurantId);
    }, []);

    useEffect(() => {
        restaurant && navigation.setOptions({ title: `${restaurant.name}` });
    }, [restaurant]);

    const fetchData = async (restaurantId: string) => {
        const rsp = await RestaurantService.get(restaurantId);
        console.log(rsp);
        setRestaurant(rsp.data);
    };

    return (
        <View style={{ flex: 1 }}>
            {restaurant && (
                <Animated.ScrollView
                    onScroll={animationHeader.onScrollEvent}
                    scrollEventThrottle={16}
                    refreshControl={
                        <RefreshControl
                            refreshing={false}
                            onRefresh={() =>
                                navigation.replace('RestaurantDetail', { id: restaurantId })
                            }
                        />
                    }
                >
                    <>
                        {pics.length > 0 ? (
                            <PicCarousel pics={pics} />
                        ) : (
                            <PicCarousel pics={[DEFAULT_RESTAURANT_IMAGE]} />
                        )}
                        {restaurantDetail.render()}
                        {restaurantDetail.renderCheckIn()}
                        {reviewHook.render()}
                        {popDish.dishes.length > 0 && popDish.render()}
                        {restaurantDetail.renderInfo()}
                        {restaurantDetail.renderDescription()}
                        {posts.posts.length > 0 && posts.render()}
                    </>
                </Animated.ScrollView>
            )}
            {restaurantDetail.renderMapSelectorBottomSheet()}
        </View>
    );
};
