import { FontAwesome } from '@expo/vector-icons';
import { Restaurant, RestaurantReview, RestaurantService } from 'gc-common';
import LottieView from 'lottie-react-native';
import React, { useEffect, useState } from 'react';
import { Text, View } from 'react-native';
import { StyledText, StyledView } from '../../components/StyledComponents';
import { Logger } from '../../utils/logger';
import { styles } from './styles';

export const useReviews = (restaurant?: Restaurant) => {
    const [restaurantReviews, setRestaurantReviews] = useState<RestaurantReview>();
    const [loading, setLoading] = useState(false);

    const normalizeReview = async (restaurant: Restaurant) => {
        setLoading(true);
        // Normalize restaurant review
        let reviews: RestaurantReview = { pros: [], cons: [] };
        if (
            !restaurant.review ||
            restaurant.review.last_updated * 1000 + 7 * 24 * 3600000 < Date.now()
        ) {
            Logger.log(`Restaurant ${restaurant.restaurant_id} review is outdated, refreshing...`);
            reviews = (await RestaurantService.refreshReview(restaurant.restaurant_id)).data;
            if (reviews) setRestaurantReviews(reviews);
        } else {
            reviews = JSON.parse(restaurant.review.aggregated_reviews);
            setRestaurantReviews(reviews);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (restaurant) {
            normalizeReview(restaurant);
        }
    }, [restaurant]);

    const render = () => {
        return (
            <View style={[styles.section]}>
                <View style={styles.titleRow}>
                    <FontAwesome name={'magic'} style={styles.titleIcon} />
                    <Text style={styles.title}>AI Review Summary</Text>
                </View>
                {loading ? (
                    <>
                        <LottieView
                            style={{ height: 100 }}
                            source={require('../../../assets/images/loader.json')}
                            autoPlay
                            loop
                        />
                    </>
                ) : (
                    <StyledView tw={'bg-[#f7feff] border border-[#91dfeb] rounded-lg p-2 px-5'}>
                        {(restaurantReviews?.pros.length || 0) > 0 && (
                            <StyledView tw={'flex-row pb-2 border-b border-light_gray'}>
                                <StyledView tw={'w-8'}>
                                    <StyledText tw={'text-primary'}>
                                        <FontAwesome name={'thumbs-up'} size={20} />
                                    </StyledText>
                                </StyledView>
                                <StyledView tw={'flex-1'}>
                                    {restaurantReviews?.pros.map((pro, index) => (
                                        <StyledText tw={'mb-0.5 text-dark_gray'} key={index}>
                                            - {pro}
                                        </StyledText>
                                    ))}
                                </StyledView>
                            </StyledView>
                        )}
                        {(restaurantReviews?.cons.length || 0) > 0 && (
                            <StyledView tw={'flex-row pt-2'}>
                                <StyledView tw={'w-8'}>
                                    <StyledText tw={'text-gray'}>
                                        <FontAwesome name={'thumbs-o-down'} size={20} />
                                    </StyledText>
                                </StyledView>
                                <StyledView tw={'flex-1'}>
                                    {restaurantReviews?.cons.map((con, index) => (
                                        <StyledText tw={'mb-0.5 text-dark_gray'} key={index}>
                                            - {con}
                                        </StyledText>
                                    ))}
                                </StyledView>
                            </StyledView>
                        )}
                    </StyledView>
                )}
            </View>
        );
    };

    return {
        render,
    };
};
