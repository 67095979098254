import * as Updates from 'expo-updates';
import { useEffect } from 'react';
import { Alert, AppState, Linking, Platform } from 'react-native';
import { Env } from '../Env';
import { Logger } from '../utils/logger';
import { checkHasUpdatedAppVersion } from '../utils/version';

export const useUpdate = () => {
    useEffect(() => {
        if (Env.APP_ENV != 'staging' && Env.APP_ENV != 'production') return;
        const subscription = AppState.addEventListener('change', async (nextAppState) => {
            if (nextAppState === 'active') {
                const hasUpdate = await Updates.checkForUpdateAsync();

                if (hasUpdate.isAvailable) {
                    Logger.log('[UPDATE] Update available found.');
                    await update();
                }
            }
        });

        return () => {
            subscription.remove();
        };
    }, []);

    const checkAppStoreUpdate = async () => {
        const hasUpdate = await checkHasUpdatedAppVersion();
        if (hasUpdate) {
            Logger.log('[UPDATE] Appstore Update available found.');
            Alert.alert(
                'Update Available',
                'This version may not work properly, please update it now!',
                [
                    {
                        text: 'Cancel',
                        onPress: () => {
                            Logger.log('[UPDATE] Appstore Update cancelled.');
                        },
                        style: 'cancel',
                    },
                    {
                        text: 'Update Now',
                        onPress: () => {
                            Logger.log('[UPDATE] Appstore Update applied.');
                            if (Platform.OS === 'android') {
                                Linking.openURL('market://details?id=com.gourmetcarte.app');
                            } else if (Platform.OS === 'ios') {
                                Linking.openURL('itms-apps://apps.apple.com/app/id1673184423');
                            }
                        },
                    },
                ]
            );
        }
    };
    const update = async () => {
        await Updates.fetchUpdateAsync();
        Alert.alert('Update Available', 'There is a new update available, please update it now!', [
            {
                text: 'Cancel',
                onPress: () => {
                    Logger.log('[UPDATE] Update cancelled.');
                },
                style: 'cancel',
            },
            {
                text: 'Update Now',
                onPress: () => {
                    Logger.log('[UPDATE] Update applied.');
                    Updates.reloadAsync();
                },
            },
        ]);
    };

    return { checkAppStoreUpdate };
};
