/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { RootStackParamList } from '../types';

const linking: LinkingOptions<RootStackParamList> = {
    prefixes: [Linking.createURL('/'), 'https://*.gourmetcarte.com'],
    config: {
        screens: {
            Root: {
                // Tab Nav
                screens: {},
            },
            // Stack Nav
            RedirectPage: 'r/:path',
            User: 'user',
            DishDetail: 'dish',
            RestaurantDetail: 'restaurant',
            PostDetail: 'post',
            PasswordReset: 'reset_password',
            Menu: 'menu',
            TermsOfService: 'terms',
            PrivacyPolicy: 'privacy',
            EditProfile: 'edit_profile',
            NotFound: '*',
        },
    },
};

const getPageName = (s: string) => {
    for (let name in linking?.config?.screens || {}) {
        // @ts-ignore
        if (linking.config.screens[name as any] === s) {
            return name;
        }
    }
    return 'Root';
};

export default linking;
export const LinkingUtil = {
    linking,
    getPageName,
};
