import React from 'react';
import { DimensionValue, Image, Text, TouchableOpacity, View } from 'react-native';
import { SkeletonPlaceholder } from '../../webLibs/SkeletonPlaceholder/SkeletonPlaceholder';
import { styles } from './FullItemCardStyles';
import { ItemCardProps } from './index';

export interface FullItemCardProps extends ItemCardProps {
    width?: DimensionValue;
    skeleton?: boolean;
    fixedHeight?: number;
}
export const FullItemCard = ({ width = '100%', skeleton, ...props }: FullItemCardProps) => {
    if (!skeleton)
        return (
            <TouchableOpacity
                activeOpacity={0.7}
                style={[styles.wrapper, { width: width, height: props.fixedHeight }]}
                onPress={props.onPress || undefined}
            >
                <Image style={styles.pic} source={{ uri: props.pic }} />
                <View style={styles.contentWrapper}>
                    <View style={styles.detailWrapper}>
                        {props.author && (
                            <Image style={styles.avatar} source={{ uri: props.author.avatar }} />
                        )}
                        <View style={styles.detailContentWrapper}>
                            <Text style={styles.title} numberOfLines={2}>
                                {props.title}
                            </Text>
                            <Text style={styles.subText} numberOfLines={1}>
                                {props.subText}
                            </Text>
                            {/*<Ratings rating={props.rating} />*/}
                        </View>
                    </View>
                    {props.description && (
                        <Text style={styles.description} numberOfLines={3}>
                            {props.description}
                        </Text>
                    )}
                    <View style={styles.extraWrapper}>
                        {/*<Text>{props.extraText}</Text>*/}
                        {/*<View style={styles.spanner} />*/}
                        {/*<Text style={styles.likes}>*/}
                        {/*    <FontAwesome name={'heart'} size={15} /> {props.likes}*/}
                        {/*</Text>*/}
                    </View>
                </View>
            </TouchableOpacity>
        );
    else
        return (
            <View style={[styles.wrapper, { width: width, height: 400 }]}>
                <SkeletonPlaceholder>
                    <>
                        <SkeletonPlaceholder.Item {...styles.pic} />
                        <View style={styles.contentWrapper}>
                            <View style={styles.detailWrapper}>
                                <SkeletonPlaceholder.Item {...styles.avatar} />

                                <View style={styles.detailContentWrapper}>
                                    <SkeletonPlaceholder.Item
                                        width={70}
                                        height={16}
                                        borderRadius={3}
                                    />
                                    <SkeletonPlaceholder.Item
                                        width={100}
                                        height={16}
                                        borderRadius={3}
                                        marginTop={5}
                                    />
                                    <SkeletonPlaceholder.Item
                                        width={40}
                                        height={16}
                                        borderRadius={3}
                                        marginTop={5}
                                    />
                                </View>
                            </View>
                            <SkeletonPlaceholder.Item
                                width={'100%'}
                                height={50}
                                borderRadius={3}
                                marginTop={12}
                            />
                            <View style={styles.extraWrapper}>
                                {/*<Text>{props.extraText}</Text>*/}
                                {/*<View style={styles.spanner} />*/}
                                {/*<Text style={styles.likes}>*/}
                                {/*    <FontAwesome name={'heart'} size={15} /> {props.likes}*/}
                                {/*</Text>*/}
                            </View>
                        </View>
                    </>
                </SkeletonPlaceholder>
            </View>
        );
};
