import { Entypo } from '@expo/vector-icons';
import {
    AutocompleteResultItem,
    Dish,
    formatAddress,
    Restaurant,
    SearchService,
    User,
} from 'gc-common';
import React, { useEffect, useRef, useState } from 'react';
import {
    DeviceEventEmitter,
    ScrollView,
    Text,
    TextInput,
    TouchableOpacity,
    View,
} from 'react-native';
import { GCButton } from '../../components/Button/GCButton';
import { SearchScreenTopHeader } from '../../components/HomeScreenTopHeader';
import { RowItem } from '../../components/RowItem/RowItem';
import { CommonStyles } from '../../components/Styles/CommonStyles';
import { useAuth } from '../../context/authContext';
import { useModal } from '../../context/modalContext';
import { useSearchHistory } from '../../states/searchHistory';
import { RootStackScreenProps } from '../../types';
import { DEFAULT_DISH_IMAGE, DEFAULT_RESTAURANT_IMAGE } from '../../types/Constants';
import { Image } from '../../webLibs/FastImage/FastImage';
import { styles as StoreRowStyles } from '../PostCreationPage/styles';
import { styles } from './styles';

export const SearchPage = ({ route, navigation }: RootStackScreenProps<'Search'>) => {
    const auth = useAuth();
    const modal = useModal();

    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
    const [restaurantsLimit, setRestaurantsLimit] = useState(3);
    const [keyWord, setKeyWord] = useState<string>('');
    const [dishes, setDishes] = useState<Dish[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [initial, setInitial] = useState(true);
    const [autocompleteData, setAutocompleteData] = useState<AutocompleteResultItem[]>([]);
    const searchHistory = useSearchHistory();
    const searchRef = useRef<TextInput>(null);
    const scrollViewRef = useRef<ScrollView>(null);
    const abortControllerRef = useRef<AbortController>(new AbortController());
    useEffect(() => {
        DeviceEventEmitter.addListener('search-submit', async (keyword) => {
            abortControllerRef.current.abort();

            if (!keyword) return;
            await fetchData(keyword);
            setInitial(false);
        });

        DeviceEventEmitter.addListener('search-typing', async (keyword) => {
            if (!keyword) {
                setAutocompleteData([]);
                return;
            }
            await fetchAutocomplete(keyword);
        });

        return () => {
            DeviceEventEmitter.removeAllListeners('search-submit');
            DeviceEventEmitter.removeAllListeners('search-typing');
        };
    }, []);

    useEffect(() => {
        // DeviceEventEmitter.emit('search-page-ready');
        //searchRef.current?.focus();
        setTimeout(() => {
            searchRef.current?.focus();
        }, 600);
    }, []);

    const fetchData = async (keyword: string) => {
        // console.log('FetchData:', keyword);
        setAutocompleteData([]);
        setKeyWord(keyword);
        searchHistory.addHistory(keyword);
        modal.open('loader');
        const rsp = await SearchService.search(keyword, 'all', auth.location);

        const _dishes = [] as Dish[];
        const _restaurants = [] as Restaurant[];
        const _users = [] as User[];

        rsp.data.forEach((item) => {
            if (item.search_type === 'dish') _dishes.push(item);
            if (item.search_type === 'restaurant') _restaurants.push(item);
            if (item.search_type === 'user') _users.push(item);
        });

        setRestaurants(_restaurants);
        setDishes(_dishes);
        setUsers(_users);
        modal.close();
        searchRef.current?.blur();
    };

    const fetchAutocomplete = async (keyword: string) => {
        // console.log('AutoComplete:', keyword);
        abortControllerRef.current.abort();
        abortControllerRef.current = new AbortController();

        try {
            const rsp = await SearchService.autocomplete(
                keyword,
                'all',
                abortControllerRef.current
            );
            setAutocompleteData(rsp.data);
        } catch (e) {
            console.log('fetchAutocomplete aborted', e);
        }
    };

    const onAutocompleteSelected = (keyword: string) => {
        console.log(keyword);
        fetchData(keyword);
    };

    const autocompleteItemIcon = {
        restaurant: 'home' as const,
        dish: 'bowl' as const,
        user: 'user' as const,
    };

    const onSelectHistory = (keyword: string) => {
        searchRef.current?.setNativeProps({ text: keyword });
        fetchData(keyword);
    };

    const handleClear = () => {
        searchHistory.clearHistory();
    };

    useEffect(() => {
        if (scrollViewRef.current) console.log('scrolling');
        setTimeout(() => {
            searchRef.current?.blur();
            scrollViewRef.current?.scrollTo({ y: 0, animated: true });
        }, 300);
        // scrollViewRef.current?.scrollTo({y: 0, animated: true});
    }, [dishes]);

    return (
        <View style={{ flex: 1 }}>
            <SearchScreenTopHeader navigation={navigation} ref={searchRef} />
            <ScrollView
                ref={scrollViewRef}
                keyboardShouldPersistTaps={'handled'}
                automaticallyAdjustKeyboardInsets={true}
            >
                {autocompleteData.length == 0 &&
                    restaurants.length === 0 &&
                    dishes.length === 0 &&
                    users.length === 0 && (
                        <View style={styles.historySection}>
                            <View style={styles.historyHeader}>
                                <Text style={styles.historyTitle}>Search History</Text>
                                <TouchableOpacity onPress={handleClear}>
                                    <Text style={styles.historyTitle}>clear</Text>
                                </TouchableOpacity>
                            </View>
                            <View style={styles.historyWrapper}>
                                {searchHistory.searchHistoryData.map((keyword) => (
                                    <TouchableOpacity
                                        style={styles.historyItem}
                                        onPress={() => onSelectHistory(keyword)}
                                        key={keyword}
                                    >
                                        <Text style={styles.historyText}>{keyword}</Text>
                                    </TouchableOpacity>
                                ))}
                            </View>
                        </View>
                    )}
                {autocompleteData.length > 0 && (
                    <View style={styles.autocompleteWrapper}>
                        {autocompleteData.slice(0, 20).map((item) => {
                            return (
                                <TouchableOpacity
                                    style={styles.autocompleteItem}
                                    key={item._id}
                                    onPress={() =>
                                        onAutocompleteSelected(item.name || item.display_name)
                                    }
                                >
                                    <Text>
                                        <Entypo
                                            name={autocompleteItemIcon[item.autocomplete_type]}
                                        />{' '}
                                        {item.name || item.display_name}
                                    </Text>
                                </TouchableOpacity>
                            );
                        })}
                    </View>
                )}
                {autocompleteData.length === 0 && users.length > 0 && (
                    <View style={[]}>
                        <ScrollView horizontal style={styles.userSection}>
                            {users.map((item) => {
                                return (
                                    <>
                                        <TouchableOpacity
                                            style={styles.userItem}
                                            onPress={() => {
                                                navigation.navigate('User', {
                                                    id: item.user_id,
                                                });
                                            }}
                                        >
                                            <Image
                                                style={styles.userAvatar}
                                                source={{ uri: item.avatar_image_url }}
                                            />
                                            <Text style={styles.userName} numberOfLines={1}>
                                                {item.display_name}
                                            </Text>
                                        </TouchableOpacity>
                                    </>
                                );
                            })}
                        </ScrollView>
                    </View>
                )}
                {autocompleteData.length === 0 && restaurants.length > 0 && (
                    <View style={CommonStyles.section}>
                        <View style={CommonStyles.titleRow}>
                            <Text style={CommonStyles.title}>Restaurants</Text>
                            {restaurantsLimit === 3 && (
                                <GCButton
                                    text={'More'}
                                    onClick={() => {
                                        navigation.navigate('SearchRestaurant', {
                                            keyword: keyWord,
                                        });
                                        // setRestaurantsLimit(1000);
                                    }}
                                />
                            )}
                        </View>
                        <View>
                            {restaurants.slice(0, restaurantsLimit).map((item) => {
                                return (
                                    <View
                                        style={StoreRowStyles.rowItemWrapper}
                                        key={item.restaurant_id}
                                    >
                                        <RowItem
                                            image={
                                                item.avatar_image_url || DEFAULT_RESTAURANT_IMAGE
                                            }
                                            title={item.name}
                                            subTitle={formatAddress(item.address)}
                                            onPress={() => {
                                                navigation.navigate('RestaurantDetail', {
                                                    id: item.restaurant_id,
                                                });
                                            }}
                                        />
                                    </View>
                                );
                            })}
                        </View>
                    </View>
                )}
                {autocompleteData.length === 0 && dishes.length > 0 && (
                    <View style={CommonStyles.section}>
                        <View style={CommonStyles.titleRow}>
                            <Text style={CommonStyles.title}>Dishes</Text>
                        </View>
                        <View>
                            {dishes.map((item) => {
                                return (
                                    <View style={StoreRowStyles.rowItemWrapper} key={item._id}>
                                        <RowItem
                                            image={item.avatar_image_url || DEFAULT_DISH_IMAGE}
                                            title={item.name}
                                            rating={item.rating}
                                            subTitle={item.description}
                                            price={item.price == 0 ? '' : `$${item.price}`}
                                            onPress={() => {
                                                navigation.navigate('DishDetail', {
                                                    id: item.dish_id,
                                                });
                                            }}
                                        />
                                    </View>
                                );
                            })}
                        </View>
                    </View>
                )}
                {!initial && dishes.length === 0 && restaurants.length === 0 && (
                    <View style={styles.noResultWrapper}>
                        <Text style={styles.noResultText}>Sorry, no results were found.</Text>
                        <GCButton
                            text={'Add a new restaurant'}
                            onClick={() => {
                                navigation.navigate('RestaurantCreation');
                            }}
                        />
                    </View>
                )}
            </ScrollView>
        </View>
    );
};
