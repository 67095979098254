import * as Application from 'expo-application';
import { ajax, compareVersions, GCJSONResponse } from 'gc-common';
import { Logger } from './logger';

export const checkHasUpdatedAppVersion = async () => {
    try {
        const currentVersion = Application.nativeApplicationVersion || '0.0.0';
        const lastVersion =
            (await ajax.get<GCJSONResponse<string>>('/api/core/version/app_latest')).data.data ||
            '0.0.0';

        return compareVersions(currentVersion, lastVersion) < 0;
    } catch (e) {
        Logger.error('Failed to check app version');
        return false;
    }
};
